import { Question } from '../types/quiz';

export const questions: Question[] = [
  {
    id: 1,
    text: "What sounds like your perfect swimming temperature?",
    imageUrl: "https://images.unsplash.com/photo-1470010762743-1fa2363f65ca?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Thermal pool with steam rising
    options: [
      { text: "Refreshingly cool mountain water", styles: { adventure: 3, coastal: 1, wild: 2, urban: 0, resort: 0 } },
      { text: "Warm thermal springs", styles: { adventure: 2, coastal: 0, wild: 1, urban: 0, resort: 3 } },
      { text: "Perfect temperature-controlled pools", styles: { adventure: 0, coastal: 0, wild: 0, urban: 3, resort: 2 } },
      { text: "Invigorating ocean waves", styles: { adventure: 1, coastal: 3, wild: 2, urban: 0, resort: 0 } },
    ],
  },
  {
    id: 2,
    text: "How far would you travel for an amazing swim?",
    imageUrl: "https://images.unsplash.com/photo-1506197603052-3cc9c3a201bd?w=800", // Person looking at map in nature
    options: [
      { text: "Hidden spots within 30 minutes", styles: { adventure: 1, coastal: 1, wild: 2, urban: 3, resort: 0 } },
      { text: "Road trip worthy destinations (2-3 hours)", styles: { adventure: 3, coastal: 2, wild: 2, urban: 0, resort: 1 } },
      { text: "International bucket list locations", styles: { adventure: 2, coastal: 2, wild: 1, urban: 0, resort: 3 } },
      { text: "Off-the-grid expeditions", styles: { adventure: 2, coastal: 1, wild: 3, urban: 0, resort: 0 } },
    ],
  },
  {
    id: 3,
    text: "What's your ideal swimming environment?",
    imageUrl: "https://images.unsplash.com/photo-1516815231560-8f41ec531527?w=800", // Stunning mountain lake
    options: [
      { text: "Pristine mountain lakes surrounded by peaks", styles: { adventure: 3, coastal: 0, wild: 2, urban: 0, resort: 1 } },
      { text: "Vibrant coastal beach clubs", styles: { adventure: 0, coastal: 2, wild: 0, urban: 1, resort: 3 } },
      { text: "Secret river swimming holes", styles: { adventure: 2, coastal: 0, wild: 3, urban: 0, resort: 0 } },
      { text: "Modern urban oases", styles: { adventure: 0, coastal: 0, wild: 0, urban: 3, resort: 2 } },
    ],
  },
  {
    id: 4,
    text: "What amenities matter most to you?",
    imageUrl: "https://images.unsplash.com/photo-1566073771259-6a8506099945?w=800", // Luxury poolside setup
    options: [
      { text: "None - just pure nature", styles: { adventure: 2, coastal: 1, wild: 3, urban: 0, resort: 0 } },
      { text: "Full service (loungers, food, drinks)", styles: { adventure: 0, coastal: 1, wild: 0, urban: 2, resort: 3 } },
      { text: "Basic facilities (changing rooms, showers)", styles: { adventure: 1, coastal: 2, wild: 0, urban: 3, resort: 1 } },
      { text: "Safety equipment and lifeguards", styles: { adventure: 0, coastal: 2, wild: 0, urban: 3, resort: 2 } },
    ],
  },
  {
    id: 5,
    text: "What's your ideal time of day for a swim?",
    imageUrl: "https://images.unsplash.com/photo-1533443144047-ea8a81e83e68?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Sunrise over water
    options: [
      { text: "Sunrise solitude", styles: { adventure: 2, coastal: 2, wild: 3, urban: 0, resort: 1 } },
      { text: "Midday energy", styles: { adventure: 1, coastal: 2, wild: 0, urban: 3, resort: 2 } },
      { text: "Sunset social hour", styles: { adventure: 0, coastal: 3, wild: 0, urban: 1, resort: 3 } },
      { text: "Moonlit dips", styles: { adventure: 3, coastal: 1, wild: 2, urban: 0, resort: 1 } },
    ],
  },
  {
    id: 6,
    text: "Who's joining your perfect swim day?",
    imageUrl: "https://images.unsplash.com/photo-1529156349890-84021ffa9107?q=80&w=2531&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Group enjoying water
    options: [
      { text: "Solo meditation", styles: { adventure: 2, coastal: 1, wild: 3, urban: 0, resort: 0 } },
      { text: "Close friends only", styles: { adventure: 3, coastal: 2, wild: 1, urban: 1, resort: 2 } },
      { text: "The more the merrier", styles: { adventure: 0, coastal: 2, wild: 0, urban: 3, resort: 3 } },
      { text: "My adventure partner", styles: { adventure: 3, coastal: 1, wild: 2, urban: 0, resort: 1 } },
    ],
  },
  {
    id: 7,
    text: "What would enhance your swimming experience?",
    imageUrl: "https://images.unsplash.com/photo-1565803974275-dccd2f933cbb?q=80&w=2671&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Cliffside view
    options: [
      { text: "Jumping from cliffs", styles: { adventure: 3, coastal: 1, wild: 2, urban: 0, resort: 0 } },
      { text: "Beachside yoga", styles: { adventure: 0, coastal: 2, wild: 0, urban: 1, resort: 3 } },
      { text: "Wildlife spotting", styles: { adventure: 2, coastal: 1, wild: 3, urban: 0, resort: 0 } },
      { text: "Beach Cleanups", styles: { adventure: 0, coastal: 1, wild: 0, urban: 2, resort: 3 } },
    ],
  },
  {
    id: 8,
    text: "What's your swimming skill level?",
    imageUrl: "https://images.unsplash.com/photo-1518673201468-69eb19661434?q=80&w=2673&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Challenging water conditions
    options: [
      { text: "Advanced - bring on the challenges", styles: { adventure: 3, coastal: 2, wild: 2, urban: 0, resort: 0 } },
      { text: "Intermediate - comfortable but cautious", styles: { adventure: 1, coastal: 2, wild: 1, urban: 2, resort: 2 } },
      { text: "Beginner - safety first", styles: { adventure: 0, coastal: 1, wild: 0, urban: 3, resort: 2 } },
      { text: "Pro - former lifeguard/instructor", styles: { adventure: 2, coastal: 3, wild: 2, urban: 1, resort: 1 } },
    ],
  }
];