import { motion } from 'framer-motion';  // Add this import

interface QuizProgressProps {
  current: number;
  total: number;
  quizName?: string;
}

// QuizProgress.tsx
export const QuizProgress: React.FC<QuizProgressProps> = ({
  current,
  total,
  quizName = "Swimming Personality Quiz"
}) => {
  const progress = ((current + 1) / total) * 100;

  return (
    <div className="w-full max-w-4xl mx-auto mb-8">
      <div className="bg-white rounded-xl p-6 shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h3 className="text-lg font-semibold text-gray-900">{quizName}</h3>
            <p className="text-sm text-gray-500">Question {current + 1} of {total}</p>
          </div>
          <div className="text-xl font-bold text-blue-600">
            {Math.round(progress)}%
          </div>
        </div>
        
        <div className="relative h-3 bg-gray-100 rounded-full overflow-hidden">
          <motion.div
            className="absolute top-0 left-0 h-full bg-gradient-to-r from-blue-500 to-blue-600"
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          />
          <div className="absolute top-0 left-0 w-full h-full opacity-50 bg-grid-pattern" />
        </div>

        {/* Progress Markers */}
        <div className="mt-4 flex justify-between">
          {Array.from({ length: total }).map((_, i) => (
            <div
              key={i}
              className={`
                w-2 h-2 rounded-full transition-all duration-300
                ${i <= current ? 'bg-blue-600' : 'bg-gray-200'}
              `}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
