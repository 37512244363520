import React from 'react';
import { Timer, Medal } from 'lucide-react';

export function QuizHeader() {
  return (
    <div className="text-center mb-8 sm:mb-12">
      <div className="flex items-center justify-center mb-4">
        <Medal className="w-10 h-10 sm:w-12 sm:h-12 text-blue-600" />
      </div>
      <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-2">Best Swim Spots Quiz</h1>
      <p className="text-base sm:text-lg text-gray-600 px-4">
        Find your perfect swimming destination tailored to your preferences.
      </p>
    </div>
  );
}