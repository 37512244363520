import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App.tsx';
import './index.css';

// Get environment variables
const domain = import.meta.env.VITE_AUTH0_DOMAIN;
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;

// Verify that environment variables are properly loaded
if (!domain || !clientId) {
  throw new Error(
    'Missing Auth0 configuration. Make sure your .env file is properly set up with VITE_AUTH0_DOMAIN and VITE_AUTH0_CLIENT_ID'
  );
}

// Calculate the redirect URI dynamically
const redirectUri = `${window.location.origin}${window.location.pathname}`;

// Create and render the root component
createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        scope: 'openid profile email',
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      useRefreshTokensFallback={true}
    >
      <App />
    </Auth0Provider>
  </StrictMode>
);