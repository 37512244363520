import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  Facebook, 
  Twitter, 
  Mail, 
  Share2, 
  Copy, 
  Check,
  Download
} from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';

interface ResultsCardProps {
  swimStyle: {
    title: string;
    description: string;
    imageUrl: string;
    traits: string[];
  };
  onRestart: () => void;
  userEmail?: string;
}

export const ResultsCard: React.FC<ResultsCardProps> = ({
  swimStyle,
  onRestart,
  userEmail
}) => {
  // State management
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const { loginWithPopup, user } = useAuth0();

  // Create sharing text and URL
  const shareText = `I'm a ${swimStyle.title} swimmer! 🏊‍♂️ Take the quiz to discover your swimming personality!`;
  const shareUrl = typeof window !== 'undefined' ? window.location.href : '';
  
  // Handle different sharing methods
  const handleShare = async (platform: 'facebook' | 'twitter' | 'email' | 'copy') => {
    if (!userEmail && !user?.email) {
      // Prompt for authentication if sharing without being logged in
      try {
        await loginWithPopup();
        // After successful login, proceed with sharing
      } catch (error) {
        console.error('Login failed:', error);
        return;
      }
    }

    switch (platform) {
      case 'facebook':
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`,
          '_blank'
        );
        break;
      case 'twitter':
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`,
          '_blank'
        );
        break;
      case 'email':
        window.location.href = `mailto:?subject=My Swimming Personality Quiz Result&body=${encodeURIComponent(
          `${shareText}\n\nTake the quiz: ${shareUrl}`
        )}`;
        break;
      case 'copy':
        try {
          await navigator.clipboard.writeText(`${shareText}\n\n${shareUrl}`);
          setIsLinkCopied(true);
          setTimeout(() => setIsLinkCopied(false), 2000);
        } catch (err) {
          console.error('Failed to copy text:', err);
        }
        break;
    }
  };

  // Generate certificate-style result image for download
  const generateCertificate = () => {
    // Implement certificate generation logic here
    // This could use HTML5 Canvas or a library like html2canvas
    console.log('Certificate generation not yet implemented');
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      className="w-full max-w-4xl mx-auto"
    >
      <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
        {/* Hero Section with Image and Title */}
        <div className="relative h-80">
          <img
            src={swimStyle.imageUrl}
            alt={swimStyle.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <h2 className="text-4xl font-bold mb-2">You Are:</h2>
              <h1 className="text-5xl font-bold mb-4">{swimStyle.title}</h1>
            </motion.div>
          </div>
        </div>

        {/* Content Section */}
        <div className="p-8">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="prose max-w-none"
          >
            {/* Description */}
            <p className="text-xl leading-relaxed text-gray-700 mb-8">
              {swimStyle.description}
            </p>

            {/* Traits */}
            <div className="flex flex-wrap gap-3 mb-8">
              {swimStyle.traits.map((trait, index) => (
                <motion.span
                  key={trait}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.6 + index * 0.1 }}
                  className="px-4 py-2 bg-blue-50 text-blue-700 rounded-full font-medium"
                >
                  {trait}
                </motion.span>
              ))}
            </div>

            {/* Share Section */}
            <div className="border-t border-gray-100 pt-8 mt-8">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-bold">Share Your Results</h3>
                <button
                  onClick={() => setShowShareOptions(!showShareOptions)}
                  className="text-blue-600 hover:text-blue-700 flex items-center gap-2"
                >
                  <Share2 className="w-5 h-5" />
                  <span>Share Options</span>
                </button>
              </div>

              {showShareOptions && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-8"
                >
                  <button
                    onClick={() => handleShare('facebook')}
                    className="flex items-center justify-center gap-2 px-6 py-3 rounded-xl text-white font-medium bg-[#1877F2] hover:bg-[#166FE5] transition-all"
                  >
                    <Facebook className="w-5 h-5" />
                    <span>Share</span>
                  </button>
                  <button
                    onClick={() => handleShare('twitter')}
                    className="flex items-center justify-center gap-2 px-6 py-3 rounded-xl text-white font-medium bg-[#1DA1F2] hover:bg-[#1A94DA] transition-all"
                  >
                    <Twitter className="w-5 h-5" />
                    <span>Tweet</span>
                  </button>
                  <button
                    onClick={() => handleShare('email')}
                    className="flex items-center justify-center gap-2 px-6 py-3 rounded-xl text-white font-medium bg-gray-600 hover:bg-gray-700 transition-all"
                  >
                    <Mail className="w-5 h-5" />
                    <span>Email</span>
                  </button>
                  <button
                    onClick={() => handleShare('copy')}
                    className="flex items-center justify-center gap-2 px-6 py-3 rounded-xl text-gray-700 font-medium bg-gray-100 hover:bg-gray-200 transition-all"
                  >
                    {isLinkCopied ? (
                      <>
                        <Check className="w-5 h-5 text-green-500" />
                        <span>Copied!</span>
                      </>
                    ) : (
                      <>
                        <Copy className="w-5 h-5" />
                        <span>Copy Link</span>
                      </>
                    )}
                  </button>
                </motion.div>
              )}

              {/* Action Buttons */}
              <div className="flex flex-col sm:flex-row gap-4 mt-8">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={onRestart}
                  className="flex-1 bg-blue-600 hover:bg-blue-700 text-white px-8 py-4 rounded-xl font-medium transition-all shadow-lg hover:shadow-xl"
                >
                  Take Quiz Again
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={generateCertificate}
                  className="flex-1 bg-white border-2 border-blue-600 text-blue-600 px-8 py-4 rounded-xl font-medium transition-all hover:bg-blue-50 flex items-center justify-center gap-2"
                >
                  <Download className="w-5 h-5" />
                  <span>Download  Certificate</span>
                </motion.button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};