import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { questions } from './data/questions';
import { QuizHeader } from './components/QuizHeader';
import { QuizProgress } from './components/QuizProgress';
import { QuestionCard } from './components/QuestionCard';
import { ResultsCard } from './components/ResultsCard';
import { QuizHero } from './components/QuizHero';
import { calculateSwimSpot } from './utils/quizLogic';
import type { QuizState } from './types/quiz';
import { EmailCaptureModal } from './components/EmailCapture';
import mixpanel from 'mixpanel-browser';

mixpanel.init('d23a6eccc0f2f8df7be58ff1af452b2a', { 
  debug: true, 
  ignore_dnt: true, 
});

// Update your QuizState type to include email
interface ExtendedQuizState extends QuizState {
  email?: string;
  sharingEnabled?: boolean;
}

function App() {
  const [quizState, setQuizState] = useState<ExtendedQuizState>({
    currentQuestionIndex: 0,
    answers: [],
    isComplete: false,
  });
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [showEmailCapture, setShowEmailCapture] = useState(false);
  const { user } = useAuth0();

  // Handle email submission
  const handleEmailSubmit = (email: string) => {
    const result = calculateSwimSpot(questions, quizState.answers);
    setQuizState(prev => ({
      ...prev,
      email,
      isComplete: true,
      result,
    }));
    setShowEmailCapture(false);

    // Track completion with mixpanel
    mixpanel.track('Quiz Completed', {
      quiz_type: 'swim_spots',
      result_type: result.title,
      email_provided: true
    });
  };

  // Modified handleSelectAnswer to show email capture before final results
  const handleSelectAnswer = (answerIndex: number) => {
    const isLastQuestion = quizState.currentQuestionIndex === questions.length - 1;
    const updatedAnswers = [...quizState.answers];
    updatedAnswers[quizState.currentQuestionIndex] = answerIndex;

    // Track answer in mixpanel
    mixpanel.track('Question Answered', {
      quiz_type: 'swim_spots',
      question_index: quizState.currentQuestionIndex,
      question_text: questions[quizState.currentQuestionIndex].text,
      answer_selected: questions[quizState.currentQuestionIndex].options[answerIndex].text
    });

    if (isLastQuestion) {
      if (user?.email) {
        // If user is already authenticated, skip email capture
        const result = calculateSwimSpot(questions, updatedAnswers);
        setQuizState(prev => ({
          ...prev,
          answers: updatedAnswers,
          isComplete: true,
          email: user.email,
          result,
        }));

        mixpanel.track('Quiz Completed', {
          quiz_type: 'swim_spots',
          result_type: result.title,
          email_provided: true,
          auth0_user: true
        });
      } else {
        // Show email capture modal
        setQuizState(prev => ({
          ...prev,
          answers: updatedAnswers,
        }));
        setShowEmailCapture(true);
      }
    } else {
      setQuizState(prev => ({
        ...prev,
        answers: updatedAnswers,
        currentQuestionIndex: prev.currentQuestionIndex + 1,
      }));
    }
  };

  // Skip email capture if needed
  const handleSkipEmail = () => {
    const result = calculateSwimSpot(questions, quizState.answers);
    setQuizState(prev => ({
      ...prev,
      isComplete: true,
      result,
    }));
    setShowEmailCapture(false);

    mixpanel.track('Quiz Completed', {
      quiz_type: 'swim_spots',
      result_type: result.title,
      email_provided: false
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {!isQuizStarted ? (
        <QuizHero onStartQuiz={() => {
          setIsQuizStarted(true);
          mixpanel.track('Quiz Started', {
            quiz_type: 'swim_spots'
          });
        }} />
      ) : (
        <div className="max-w-4xl mx-auto px-4 py-8">
          <QuizHeader />
          {!quizState.isComplete ? (
            <>
              <QuizProgress 
                current={quizState.currentQuestionIndex} 
                total={questions.length}
              />
              <QuestionCard
                question={questions[quizState.currentQuestionIndex]}
                selectedAnswer={quizState.answers[quizState.currentQuestionIndex] ?? null}
                onSelectAnswer={handleSelectAnswer}
              />
            </>
          ) : (
            quizState.result && (
              <ResultsCard
                swimStyle={quizState.result}
                onRestart={() => {
                  setQuizState({
                    currentQuestionIndex: 0,
                    answers: [],
                    isComplete: false,
                  });
                  mixpanel.track('Quiz Restart', {
                    quiz_type: 'swim_spots'
                  });
                }}
                userEmail={quizState.email}
              />
            )
          )}

          {showEmailCapture && (
            <EmailCaptureModal 
              onSubmit={handleEmailSubmit}
              onSkip={handleSkipEmail}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default App;