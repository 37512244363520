import { SwimSpotStyle } from '../types/quiz';

export const spotStyles: SwimSpotStyle[] = [
  {
    id: 'adventure',
    title: 'The Mountain Explorer',
    description: 'You seek out high-altitude lakes and hidden alpine pools. Your perfect swim spots are rewarding destinations at the end of scenic hikes, offering pristine waters and breathtaking mountain views. Pack your dry bag - adventure awaits!',
    imageUrl: 'https://images.unsplash.com/photo-1508181441164-3c8e9bd4575a?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Find: Stunning mountain lake with peaks reflection
    traits: ['adventurous', 'determined', 'nature-loving', 'athletic'],
    recommendations: [
      'Alpine Lakes of the Swiss Alps',
      'Crater Lake, Oregon',
      'Lake Louise, Canadian Rockies',
      'Mountain Tarns of New Zealand'
    ]
  },
  {
    id: 'coastal',
    title: 'The Ocean Aficionado',
    description: 'The rhythm of the tides calls to you. From dramatic ocean pools carved into cliffs to protected marine coves, you thrive where the land meets the sea. Your ideal spots combine natural beauty with the invigorating energy of ocean swimming.',
    imageUrl: 'https://images.unsplash.com/photo-1510122464847-ed1effe080f5?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Find: Natural ocean pool with waves crashing
    traits: ['wave-rider', 'salt-seeker', 'beach-lover', 'free-spirited'],
    recommendations: [
      'Bondi Icebergs, Sydney',
      'Natural Pools of Madeira',
      'Tidal Pools of Cornwall',
      'La Jolla Cove, California'
    ]
  },
  {
    id: 'wild',
    title: 'The Hidden Gem Hunter',
    description: 'You live for discovering secret swimming holes and remote water spots that few others know about. From forest pools fed by waterfalls to hidden river bends, you seek out the road less traveled and savor the solitude of wild swimming.',
    imageUrl: 'https://images.unsplash.com/photo-1533105045747-b9d71a0955f9?q=80&w=2570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Find: Secret waterfall pool in forest
    traits: ['explorer', 'solitude-seeker', 'naturalist', 'adventurous'],
    recommendations: [
      'Fairy Pools, Isle of Skye',
      'Havasu Falls, Arizona',
      'Hidden Cenotes of Yucatan',
      'Warren Falls, Vermont'
    ]
  },
  {
    id: 'urban',
    title: 'The City Swimmer',
    description: 'You appreciate the convenience and reliability of well-designed urban swimming spots. From rooftop infinity pools to restored historic lidos, you enjoy combining the accessibility of city life with exceptional swimming experiences.',
    imageUrl: 'https://images.unsplash.com/photo-1698094276333-d7239da6052f?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Find: Modern urban rooftop pool
    traits: ['practical', 'social', 'design-conscious', 'efficient'],
    recommendations: [
      'London Fields Lido',
      'Badeschiff, Berlin',
      'Brooklyn Bridge Park Pop-Up Pool',
      'Seoul Urban Floating Pool'
    ]
  },
  {
    id: 'resort',
    title: 'The Luxury Seeker',
    description: 'Your ideal swimming experience combines natural beauty with premium amenities. From infinity pools overlooking the ocean to exclusive beach clubs, you appreciate how thoughtful design and service can enhance the joy of swimming.',
    imageUrl: 'https://images.unsplash.com/photo-1605537964076-3cb0ea2ff329?q=80&w=2632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Find: Luxury infinity pool with spectacular view
    traits: ['sophisticated', 'comfort-lover', 'aesthetic', 'social'],
    recommendations: [
      'Marina Bay Sands, Singapore',
      'Blue Lagoon, Iceland',
      'Hanging Gardens of Bali',
      'Santorini Infinity Pools'
    ]
  }
];