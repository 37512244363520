import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { motion } from 'framer-motion';
import { Mail, Lock } from 'lucide-react';

interface EmailCaptureModalProps {
  onSubmit: (email: string) => void;
  onSkip?: () => void;
}

export const EmailCaptureModal: React.FC<EmailCaptureModalProps> = ({ onSubmit, onSkip }) => {
  const [email, setEmail] = useState('');
  const [shareEnabled, setShareEnabled] = useState(true);
  const { loginWithPopup, user } = useAuth0();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (user) {
      // If user is authenticated, use their email
      onSubmit(user.email || email);
    } else {
      // If not authenticated, either use the entered email or prompt for Auth0 login
      if (shareEnabled) {
        try {
          await loginWithPopup();
          // After successful login, Auth0 will provide the user object
          // The useAuth0 hook will automatically update
        } catch (error) {
          console.error('Login failed:', error);
        }
      } else {
        // Just submit the email without authentication
        onSubmit(email);
      }
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
    >
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white rounded-xl p-6 max-w-md w-full shadow-xl"
      >
        <div className="text-center mb-6">
          <Mail className="w-12 h-12 mx-auto mb-4 text-blue-600" />
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Almost There!</h2>
          <p className="text-gray-600">Enter your email to see your results and optionally share them with friends.</p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="you@example.com"
              required
            />
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="share"
              checked={shareEnabled}
              onChange={(e) => setShareEnabled(e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label htmlFor="share" className="text-sm text-gray-600">
              Allow sharing results with friends
            </label>
          </div>

          <div className="space-y-2">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center space-x-2"
            >
              <Lock className="w-4 h-4" />
              <span>Continue to Results</span>
            </button>
            {onSkip && (
              <button
                type="button"
                onClick={onSkip}
                className="w-full text-gray-600 px-4 py-2 hover:text-gray-900 transition-colors text-sm"
              >
                Skip for now
              </button>
            )}
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};